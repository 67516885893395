define("discourse/plugins/discourse-subscriptions/discourse/components/login-required", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "discourse_subscriptions.subscribe.unauthenticated"}}</h3>
  
  <DButton
    @label="log_in"
    @action={{route-action "showLogin"}}
    @icon="user"
    class="btn btn-primary login-required subscriptions"
  />
  */
  {
    "id": "WocRnGuq",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,0],[\"discourse_subscriptions.subscribe.unauthenticated\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],[[24,0,\"btn btn-primary login-required subscriptions\"]],[[\"@label\",\"@action\",\"@icon\"],[\"log_in\",[28,[37,2],[\"showLogin\"],null],\"user\"]],null]],[],false,[\"i18n\",\"d-button\",\"route-action\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/login-required.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "login-required"));
});