define("discourse/plugins/discourse-subscriptions/discourse/routes/subscribe", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscribeRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    beforeModel() {
      const pricingTableEnabled = this.siteSettings.discourse_subscriptions_pricing_table_enabled;
      if (pricingTableEnabled) {
        this.router.transitionTo("subscriptions");
      }
    }
  }
  _exports.default = SubscribeRoute;
});